<template>
    <user-info-layout>
        <template #heading-text>How big is your organization?</template>
        <v-radio-group v-model="organizationSelector">
            <v-radio
                class="mt-4"
                v-for="option in options"
                :key="option.value"
                :label="option.label"
                :value="option.value"
                @click="handleClick"
            ></v-radio>
        </v-radio-group>
        <v-btn
            v-if="showNextButton" 
            large
            class="mt-4"
            :width="$vuetify.breakpoint.xs ? '100%' : '25%'"
            :elevation="0"
            color="primary"
            @click="handleClick"
        >
            Next
        </v-btn>
    </user-info-layout>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import UserInfoLayout from '../../layouts/UserInfoLayout.vue';

export default {
    name: "Organization",
    components: {
        UserInfoLayout,
    },
    computed: {
        ...mapGetters({
            organizationSize: 'userInfoForm/organizationSize',
        }),
        organizationSelector: {
            get() {
                return this.organizationSize;
            },
            set(val) {
                this.setOrganizationSize(val);
            }
        },
        showNextButton() {
            return !!this.organizationSize;
        }
    },
    data() {
        return {
            options: [
                {
                    label: '1-49',
                    value: '1-49',
                },
                {
                    label: '50-249',
                    value: '50-249',
                },
                {
                    label: '250-999',
                    value: '250-999',
                },
                {
                    label: '1000+',
                    value: '1000+',
                },
            ],
        };
    },
    methods: {
        ...mapMutations({
            setOrganizationSize: 'userInfoForm/setOrganizationSize',
        }),
        handleClick() {
            this.$router.push({ name: 'user-info.team' });
        },
    },
};
</script>
